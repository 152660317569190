<template>
  <v-menu
    ref="menu"
    v-model="menuDate"
    close-on-click
    offset-overflow
    nudge-right="10px"
    nudge-bottom="36px"
    :close-on-content-click="false"
    transition="scale-transition">
    <template #activator="{ on, attrs: inputAttrs }">
      <v-text-field
        ref="dateField"
        v-model="datePickerNewValue"
        style="width: 100%"
        clearable
        hide-details
        clear-icon="mdi-arrow-down"
        label="YYYY-MM-DD"
        class="mt-5 v-text-field__rounded v-text-field__date"
        v-bind="{ ...inputAttrs, ...$attrs }"
        @keypress.prevent
        v-on="on"
        @blur="onBlur"
        @keyup.enter="onKeyUpEnter"
        @click:clear="onClear">
        <template
          slot="prepend">
          <img
            class="img-date"
            width="22"
            height="22"
            src="@/assets/icons/ion_calendar-number.svg"
            v-on="on">
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      v-model="datePickerNewValue"
      :color="color"
      :header-color="headerColor"
      :min="min">
      <v-spacer />
      <v-btn
        outlined
        @click="menuDate = false">
        Cancel
      </v-btn>
      <v-btn
        :disabled="!canSave"
        color="blue"
        class="white--text"
        elevation="0"
        @click="onSave">
        Save
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
import { mask } from 'vue-the-mask';
import validations from '@/utils/validations';
import { NOT_VALID } from '@/constants/validations';
const { validateDatePicker } = validations;
export default {
  name: 'AppDatePicker',
  directives: {
    mask,
  },
  props: {
    color: {
      type: String,
      default: 'green',
    },
    headerColor: {
      type: String,
      default: 'darkGrey',
    },
    min: {
      type: String,
      default: '1970-01-01',
    },
    datePickerValue: {
      type: String,
      default: '',
    },
    canSave: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formatMask: '####-##-##',
      menuDate: false,
    };
  },
  computed: {
    datePickerNewValue: {
      get() {
        return this.datePickerValue;
      },
      set(val) {
        this.onCheckValue(val, this.datePickerValue);
      },
    },
  },
  watch: {
    menuDate(val) {
      val ? this.onOpen() : this.onCancel();
    },
  },
  mounted() {
    this.onCheckValue(this.datePickerValue, '');
  },
  methods: {
    onCheckValue(value, oldValue) {
      const { validated, reason = '' } = validateDatePicker(value);
      if (validated) {
        this.$emit('on-date-picker-value-event', value);
        return;
      }
      if (reason === NOT_VALID) {
        this.$emit('on-date-picker-value-event', oldValue);
        return;
      }
      console.error('onCheckValue: Only string are required');
    },
    onOpen() {
      this.$emit('on-open');
      this.onCheckValue(this.datePickerValue, '');
    },
    onCancel() {
      this.$emit('on-cancel');
    },
    onSave() {
      this.menuDate = false;
      this.$emit('on-save');
    },
    onBlur() {
      this.$emit('on-blur');
    },
    onKeyUpEnter() {
      this.$emit('on-enter');
    },
    onClear() {
      this.$emit('on-clear');
    },
  },
};
</script>
<style scoped>
.img-date {
  cursor: pointer;
}
.v-card.v-picker--date {
  padding-left: 12px;
  padding-right: 12px;
}
</style>
