<template>
  <app-dialog
    v-model="isOpenedDialog"
    :value.sync="isOpenedDialog"
    width="440"
    content-class="v-dialog__form">
    <template
      #activator="{ on, attrs }">
      <div
        class="px-0"
        v-bind="attrs"
        v-on="on">
        <slot
          :btnText="'Add to project'"
          name="buttonWrapper">
          <p>
            <app-icon-btn
              icon-height="17px"
              wrapper-class="px-0"
              show-tooltip
              bottom
              icon-name="mdi-plus"
              icon-color="lightGrey">
              Add to project
            </app-icon-btn>
          </p>
        </slot>
      </div>
    </template>
    <div class="dialog-content">
      <v-card>
        <v-card-title>
          <div class="d-flex align-center">
            <span>Add to project</span>
          </div>
          <span
            class="sourcery__icon-wrapper black"
            @click="isOpenedDialog = false">
            <v-icon
              color="white"
              size="15">
              mdi-close
            </v-icon>
          </span>
        </v-card-title>
        <v-card-text class="items-wrapper">
          <div
            class="items">
            <div
              class="item-wrapper">
              <v-autocomplete
                v-model="projectToAddProduct"
                :allow-overflow="false"
                :disabled="showSpinner"
                :items="activeProjectsBySearchText"
                :multiple="false"
                :menu-props="{
                  maxHeight: '220px',
                }"
                :search-input.sync="projectSearchText"
                class="duplicate-products-autocomplete"
                color="black"
                item-text="projectTitle"
                item-value="id"
                placeholder="Choose project to add product"
                attach
                return-object
                clearable
                chips>
                <template #no-data>
                  <span class="pl-4">
                    No projects available
                  </span>
                </template>
                <template v-slot:item="{ item, on }">
                  <v-tooltip
                    bottom
                    :disabled="!item.disabled">
                    <template #activator="{ on: onTooltip }">
                      <v-layout
                        style="padding: 0"
                        wrap
                        v-on="onTooltip">
                        <v-list-item
                          :ripple="false"
                          v-on="on">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.projectTitle }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-layout>
                    </template>
                    <span>
                      {{ item.reason }}
                    </span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="pt-6 d-flex justify-end">
          <template>
            <v-btn
              outlined
              @click="isOpenedDialog = false">
              Cancel
            </v-btn>
            <v-btn
              :ripple="false"
              :loading="showSpinner"
              color="blue"
              class="white--text"
              elevation="0"
              @click="addToProject">
              Add
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </div>
  </app-dialog>
</template>
<script>
import {
  mapState, mapGetters, mapActions, mapMutations,
} from 'vuex';
import { SORTING_MODE_LAST_ACCESSED } from '@/constants/sortable';
import { PROJECT_PERMISSIONS_FOR_ADD_PRODUCT } from '@/services/graphql/fragments';
import { getFullLinkForWorkspaces } from '@/utils';
import ScheduleCells from '@/services/graphql/scheduleCells';
import API from '@/services/graphql';
import {
  cloneDeep, omit,
} from 'lodash';
import { PROJECT } from '@/constants/cores';
export default {
  name: 'ProductAddToProject',
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpenedDialog: false,
      showSpinner: false,
      projectToAddProduct: null,
    };
  },
  computed: {
    ...mapState('Projects', ['searchText', 'active']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters({
      activeProjectsBySearchText: 'Projects/activeProjectsBySearchText',
      userId: 'userId',
    }),
    projectSearchText: {
      get() {
        return this.searchText;
      },
      set(val) {
        this.setSearchText(val);
      },
    },
  },
  watch: {
    async isOpenedDialog(val) {
      if (val) {
        this.showSpinner = true;
        await this.getProjects({
          status: 'active',
          scanIndexForward: false,
          orderBy: SORTING_MODE_LAST_ACCESSED,
          customResponse: PROJECT_PERMISSIONS_FOR_ADD_PRODUCT,
        });
        this.showSpinner = false;
        return;
      }
      this.projectSearchText = '';
      this.setProjectsList({
        status: 'active',
        projects: [],
      });
    },
  },
  methods: {
    ...mapMutations('Projects', ['setSearchText', 'setProjectsList']),
    ...mapActions('Projects', ['getProjects']),
    ...mapActions(['handleError']),
    ...mapMutations(['openSnackBar', 'spinner']),
    async addToProject() {
      try {
        this.spinner(true);
        const { activeWorkspaceId: workspaceId, item, $route } = this;
        const { id: projectId, projectTitle: title } = this.projectToAddProduct;
        const scheduleData = await API.getScheduleId({
          workspaceId,
          projectId,
        });
        const { id: scheduleId } = scheduleData.data.response;
        const { id } = item;
        const clonedItem = cloneDeep(item);
        const clonedItemWithoutId = omit(clonedItem, ['id']);
        await ScheduleCells.updateCell({
          workspaceId,
          col: 'Product Reference',
          value: JSON.stringify({
            id,
            title,
            fields: Object.keys(clonedItemWithoutId),
            collectionID: $route.params.id,
          }),
          row: {
            rowId: 'new_row',
            scheduleId,
          },
          projectId,
        });
        const { userId } = this;
        await API.updateTimestampLastAccessed({
          resourceType: PROJECT,
          resourceId: projectId,
          userId,
        });
        this.spinner(false);
        this.isOpenedDialog = false;
        this.openSnackBar({
          text: `
            <div>
                Product was successfully added to ${title}
                <a target="_blank" href="${getFullLinkForWorkspaces(`project/${projectId}`)}">OPEN</a>
            </div>
          `,
          timeout: 5000,
        });
      } catch (e) {
        this.handleError(e);
      }
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-list-item--disabled {
  opacity: 0.5;
  pointer-events: auto;
}
.duplicate-products-autocomplete {
  ::v-deep.v-select__slot {
    max-height: 85px;
    overflow-y: auto;
    overflow-x: hidden
  }
}
.items-wrapper {
  min-height: 335px;
  max-height: 50vh;
}
.v-card__actions {
  padding: 16px;
}
::v-deep .create-collection.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: var(--v-blue-base) !important;
}
.create-collection {
  cursor: pointer;
  &:before {
    background-color: var(--v-blue-base);
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
}
::v-deep .v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
::v-deep .theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent !important;
}
</style>
