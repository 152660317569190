<template>
  <fragment>
    <!-- skeleton while searching -->
    <div
      v-if="!activeHeader"
      class="sourcery__container">
      <CollectionsBaseSkeleton :show-search="true" />
    </div>

    <!-- empty page -->
    <AppEmptyList
      v-else-if="isEmpty && activeHeader"
      class="pt-32"
      :from="isLibraryCollections ? 'library-collections' : 'collections'" />

    <!-- main content -->
    <template v-else>
      <!-- search bar -->
      <CollectionsSearch
        :is-store-product-preload="isStoreProductPreload"
        :custom-search-keyword="isWorkspacePage ? (workspacePage.name || '') : ''"
        v-bind="propsDataForSearching" />

      <div
        v-if="!searchProductsMode"
        class="sourcery__container d-flex flex-column gap-6">
        <!-- gallery/list view switch -->
        <div class="d-flex justify-space-between gap-4">
          <!-- left options -->
          <div class="d-flex gap-4 align-center">
            <AppSelect
              :default-item="selectedCollectionGroup"
              :items="filteredListofCollectionGroups()"
              @resort="selectedCollectionGroup = $event" />

            <router-link
              :to="{ name: 'libraries' }"
              class="fs-14 black--text text-uppercase">
              View Libraries
            </router-link>
          </div>

          <!-- right options -->
          <div class="d-flex gap-4">
            <AppSort
              :default-item="defaultItem"
              :sort-items="sortItems"
              @resort="fetchCollections" />

            <AppSwitcherToListing />
          </div>
        </div>

        <CollectionListing
          v-if="isListingView"
          :collections-list="filteredList"
          :group="selectedCollectionGroup.group"
          @click-action-menu="onClickActionMenu({
            ...$event,
          })"
          @onIntersect="onIntersect({
            ...$event,
            list: collection.list,
            collectionGroup: collection.group,
            pagination: collection.paginationState,
            allowGetAllItems: collection.allowGetAllItems,
          })" />

        <CollectionsTable
          v-else
          :collections-list="filteredList"
          :group="selectedCollectionGroup.group"
          @click-action-menu="onClickActionMenu({
            ...$event,
          })"
          @onIntersect="onIntersect({
            ...$event,
            list: collection.list,
            collectionGroup: collection.group,
            pagination: collection.paginationState,
            allowGetAllItems: collection.allowGetAllItems,
          })" />
      </div>
    </template>

    <div class="buttons-container">
      <CollectionCreateOrUpdate
        v-if="!searchProductsMode"
        v-on="$listeners" />
      <CollectionsCreateProductDialog
        :prop-library-id="currentLibraryId"
        :is-store-product-preload="isStoreProductPreload"
        v-on="$listeners" />
    </div>

    <!-- sharing dialog -->
    <ShareAdvancedDialog
      :start-subscribe="true"
      :disable-actions="disableActionsSharing"
      :disabled="!(disableActionsSharing && !activeCollection.follow)"
      :invite-to-text-type="COLLECTION"
      :item="activeCollection"
      @manageSharedUser="
        manageSharedUser({
          variables: {
            ...$event.variables,
            collectionId: activeCollection.id,
          },
          criteria: $event.criteria,
        })
      " />

    <!-- 3 dots menu -->
    <CollectionsActions
      v-bind="actionsProps"
      :show-activator="false"
      :item.sync="selectedItem"
      :position-x="positionX"
      :position-y="positionY"
      :is-community-collections="isCommunityCollections"
      :value.sync="showActionsCard"
      @manageActions="manageActions" />
  </fragment>
</template>
<script>
import {
  mapState, mapActions, mapMutations, mapGetters,
} from 'vuex';

// components
import AppEmptyList from '@/components/App/AppEmptyList';
import AppSelect from '@/components/App/AppSelect';
import AppSwitcherToListing from '@/components/App/AppListingElements/AppSwitcherToListing';
import CollectionsActions from '@/components/Collections/CollectionsActions';
import CollectionsBaseSkeleton from '@/components/Collections/CollectionsBaseSkeleton';
import CollectionCreateOrUpdate from '@/components/CollectionsLibrarysRelocate';
import CollectionsCreateProductDialog from '@/components/Collections/CollectionsCreateProductDialog';
import CollectionListing from '@/components/Listing/Collections';
import CollectionsSearch from '@/components/Collections/CollectionsSearch';
import CollectionsTable from '@/components/Collections/CollectionsTable';
import ShareAdvancedDialog from '@/components/CollectionsLibrarysRelocate/ShareAdvancedDialog';

// mixins
import AppActionDotsMenu from '@/mixins/AppActionDotsMenu';
import LoadingSpinnerReset from '@/mixins/LoadingSpinnerReset';
import ManageCollectionsActions from '@/mixins/ManageCollectionsActions';
import propsMyCollections from '@/mixins/propsMyCollections';
import PropsUtils from '@/mixins/PropsUtils';
import ToggleViewCondition from '@/mixins/ToggleViewCondition';

// utils
import {
  getFullLinkForWorkspaces, hasCreatorAccess, isOwner, hasCreatorAdminAccess,
} from '@/utils';

// constants
import {
  COMMUNITY_ID, ROUTE_COMMUNITY_COLLECTION,
} from '@/constants';
import { COLLECTION } from '@/constants/cores';
import { COMMENT_RESOURCE_TYPE } from '@/constants/comments';
import { NUMBER_OF_COLLAPSED_COLLECTION_IN_ROW } from '@/constants/collectionsList';
import {
  COLLAPSE_REORDER_SUBSCRIPTION, COLLECTION_ACCESS,
} from '@/constants/userPermissions';

// services
import SortingApi from '@/services/graphql/sorting';

export default {
  name: 'CollectionsWrapper',
  components: {
    AppEmptyList,
    AppSelect,
    AppSwitcherToListing,
    CollectionsActions,
    CollectionsBaseSkeleton,
    CollectionsTable,
    CollectionListing,
    CollectionsSearch,
    ShareAdvancedDialog,
    CollectionCreateOrUpdate,
    CollectionsCreateProductDialog,
    AppSort: () => import('@/components/App/AppSortV2'),
  },
  mixins: [
    AppActionDotsMenu,
    LoadingSpinnerReset,
    ManageCollectionsActions,
    propsMyCollections,
    PropsUtils,
    ToggleViewCondition,
  ],
  props: {
    canUseQuickViewLink: {
      type: Boolean,
      default: false,
    },
    expandedCollections: {
      type: Object,
      default: () => {
      },
    },
    filteredListOfCollections: {
      type: Array,
      default: () => [],
    },
    hideColumnMembers: {
      type: Boolean,
      default: false,
    },
    isLibraryCollections: {
      type: Boolean,
      default: false,
    },
    isCommunityCollections: {
      type: Boolean,
      default: false,
    },
    isWorkspacePage: {
      type: Boolean,
      default: false,
    },
    isWorkspaceEditPage: {
      type: Boolean,
      default: false,
    },
    isSharedPage: {
      type: Boolean,
      default: false,
    },
    isStoreProductPreload: {
      type: Boolean,
      default: false,
    },
    sectionsOrder: {
      type: Array,
      default: () => [],
    },
    useSectionSettings: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      COMMUNITY_ID,
      COLLECTION,
      actionsProps: {
      },
      selectedCollectionGroup: null,
      allCollections: null,
    };
  },
  computed: {
    ...mapState(['isMobile', 'isLoadingRowData']),
    ...mapState('Collections', ['activeCollection', 'searchProductsMode']),
    ...mapState('Libraries', ['librariesList']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('Collections', ['getAppropriateCollectionsGroups']),
    ...mapGetters('Libraries', ['getCustomLibraryById']),
    ...mapGetters('FeatureFlags', [
      'getCollectionsLimit_100',
      'mobileViewOptimizationsForTradeShow',
      'useMakePageFollowable',
      'useSkeleton',
      'useLazyLoading',
    ]),
    ...mapGetters('UserProfile', ['isSortedCollectionByLastAccessed']),
    ...mapGetters('UserRoles', [
      'canCreateCollectionInCustomLibrary',
      'canCreateCollectionInDefaultLibrary',
      'findUserRoleInLibrary',
    ]),
    ...mapGetters('Workspace', ['findWorkspacePageByPageId']),
    accessToken() {
      return this.$route?.query?.accessToken;
    },
    disableActionsSharing() {
      return ![ROUTE_COMMUNITY_COLLECTION, 'community-collections'].includes(
        this.$route.name
      );
    },
    filteredList() {
      const { filteredListOfCollections, selectedCollectionGroup, $store } = this;
      let responseArr = [];

      if (selectedCollectionGroup.value && selectedCollectionGroup.value === 'all') {
        filteredListOfCollections.map(group => {
          responseArr.push(
            ...$store.state.Collections[group.list],
          );
        });
      } else {
        responseArr.push(
          ...$store.state.Collections[selectedCollectionGroup.list],
        );
      }
      return responseArr;
    },
    getCreateCollectionRule() {
      if (this.isCustomLibrary) {
        return this.canCreateCollectionInCustomLibrary;
      }
      return this.canCreateCollectionInDefaultLibrary;
    },
    getLibraryById() {
      const { paramLibraryId: libraryId } = this;
      return this.$store.getters['Libraries/getLibraryById'](this.activeHeader?.libraryId || libraryId);
    },
    getLibraryName() {
      return this.getLibraryById.name;
    },
    getUserLibraryRole() {
      if (!this.activeHeader) return '';
      return this.findUserRoleInLibrary({
        ...this.activeHeader,
        checkIsPublishCollection: false,
      });
    },
    isAllowedCreateCollection() {
      const { mobileViewOptimizationsForTradeShow: flag } = this;
      if (!flag) return false;
      const { allowed = false } = this.getCreateCollectionRule(this.getUserLibraryRole) || {
      };
      return allowed;
    },
    isCustomLibrary() {
      return this.activeHeader?.kind == 'custom';
    },
    isEmpty() {
      if (this.isFetchingForAny) return false;

      for (const collList of this.filteredListOfCollections) {
        const listName = collList.list;
        const any = this.$store.state.Collections[listName].some(item => item && item.status !== 'deleted');
        if (any) return false;
      }

      return true;
    },
    isFetchingForAny() {
      if (!this.useLazyLoading) return false;
      const collFetchingState = this.$store.state.Collections.isFetching;

      if (collFetchingState.workspaceCollections
        || collFetchingState.publishedCollections
        || collFetchingState.followedCollections
        || collFetchingState.communityCollections) {
        return true;
      }

      const wsFetchingState = this.$store.state.Workspace.isFetching;
      if (wsFetchingState.workspacePages) return true;

      return false;
    },
    isListingView() {
      return this.toggleViewCondition('librariesRelatedPages');
    },
    paramLibraryId() {
      if (this.isWorkspacePage) {
        return COMMUNITY_ID;
      }
      return this.$route.params.id;
    },
    showAddIcon() {
      return !this.searchProductsMode && this.$route.name !== 'community-collections';
    },
    workspacePage() {
      const workspacePage = this.findWorkspacePageByPageId(this.$route.params.pageId);
      if (!workspacePage || !this.isWorkspacePage) {
        return {
          logo: '',
        };
      }
      return workspacePage;
    },
  },
  watch: {
    /**
     * @todo refactor and leave in store
     * state for products mode
     * @param val
     */
    searchProductsMode(val) {
      this.setSearchProductsMode(val);
    },
    activeHeader: function(newAH, oldAH) {
      if (!oldAH && newAH) this.getAppropriateComponentInfo();
    },
  },
  created() {
    this.allCollections = {
      ...this.filteredListOfCollections[0],
      name: 'All Collections',
      value: 'all',
    };
    this.selectedCollectionGroup = this.allCollections;
  },
  async mounted() {
    this.getAppropriateComponentInfo();
    if (!this.isCommunityCollections) {
      this.getSectionSettings();
      if (this.isSharedPage) {
        return;
      }
      this.setSubscriptionsCollapse();
      this.subscribeTotalUnreadCounter({
        resourceType: COMMENT_RESOURCE_TYPE.COLLECTION,
        libraryId: this.paramLibraryId,
      });
      this.subscribeCollectionsAccess();
    } else if (!this.isSharedPage) {
      this.onWorkspacePageChange();
    }
  },
  beforeDestroy() {
    if (!this.isCommunityCollections) {
      this.closeProgressLinear();
      this.removeSubscriptions([COLLAPSE_REORDER_SUBSCRIPTION,
        `${COMMENT_RESOURCE_TYPE.COLLECTION}_unread_counter`, COLLECTION_ACCESS]);
    } else {
      this.removeSubscriptions(['workspace_page_subscription']);
    }
    this.filteredListOfCollections.map(el => {
      this.setIsCancelled({
        group: el.group,
        value: null,
      });
    });
  },
  methods: {
    ...mapActions({
      handleError: 'handleError',
      removeSubscriptions: 'ManageSubscriptions/removeSubscriptions',
      subscriptionsCollapse: 'CollapseSections/subscriptionsCollapse',
      getWorkspaceSectionSettings: 'CollapseSections/getWorkspaceSectionSettings',
      getCollectionsList: 'Collections/getCollectionsList',
      manageSharedUser: 'Collections/manageSharedUser',
      getLibrariesList: 'Libraries/getLibrariesList',
      subscribeTotalUnreadCounter: 'Comments/subscribeTotalUnreadCounter',
      subscribeCollectionsAccess: 'Collections/subscribeCollectionsAccess',
      getCustomLibraryCollectionsList: 'Collections/getCustomLibraryCollectionsList',
      listWorkspacePages: 'Workspace/listWorkspacePages',
      onWorkspacePageChange: 'Workspace/onWorkspacePageChange',
    }),
    ...mapMutations({
      setUserData: 'UserProfile/setUserData',
      closeProgressLinear: 'closeProgressLinear',
      spinner: 'spinner',
      setIsLoadingRowData: 'setIsLoadingRowData',
      setIsCancelled: 'Collections/setIsCancelled',
      setSearchProductsMode: 'Collections/setSearchProductsMode',
      openInfoModal: 'openInfoModal',
    }),
    hasCreatorAccess,
    isOwner,
    hasCreatorAdminAccess,
    filteredListofCollectionGroups() {
      let groupNames = [
        this.allCollections,
        ...this.filteredListOfCollections,
      ];
      return groupNames;
    },
    getExpandedAmount({ expandedAmount = 0, rowsToShow = 0 } = {
    }) {
      if (expandedAmount) {
        return expandedAmount;
      }
      if (!rowsToShow) {
        return 0;
      }
      const { width } = this.$vuetify.breakpoint;
      if (width > 1110) {
        return NUMBER_OF_COLLAPSED_COLLECTION_IN_ROW.BIGGEST * rowsToShow;
      }
      if (width > 867) {
        return NUMBER_OF_COLLAPSED_COLLECTION_IN_ROW.MIDDLE * rowsToShow;
      }
      if (width > 500) {
        return NUMBER_OF_COLLAPSED_COLLECTION_IN_ROW.MOBILE_BIG * rowsToShow;
      }
      return NUMBER_OF_COLLAPSED_COLLECTION_IN_ROW.MOBILE_SMALL * rowsToShow;
    },
    async setSubscriptionsCollapse() {
      const { paramLibraryId: libraryId } = this;
      await this.subscriptionsCollapse({
        libraryId,
      });
    },
    async onClickActionMenu({ item, event }) {
      const collection = this.selectedCollectionGroup;
      const { group = null, actionTypes: actionsConditions = [], actions: showActions = false } = collection;
      const { kind = '' } = this.activeHeader;

      this.setMenuItem({
        item,
        event,
      });

      let finalActions = [...actionsConditions];
      if (group === 'wsPage' && !this.useMakePageFollowable) {
        finalActions = [];
      }
      this.actionsProps = {
        group,
        actionsConditions: finalActions,
        showActions,
        kind,
      };
    },
    getSectionSettings() {
      const { paramLibraryId: libraryId } = this;
      this.getWorkspaceSectionSettings({
        libraryId,
      });
    },
    async getAppropriateComponentInfo() {
      if (this.activeHeader || this.isSharedPage) {
        await this.fetchCollections();
      }
      if (!this.librariesList.length && !this.isSharedPage) {
        await this.getLibrariesList();
      }
    },
    getFullLinkForWorkspaces,
    collectionOptions(pagination) {
      return this.$store.state.Collections[pagination];
    },
    async fetchCollections(sortedParam) {
      this.setSpinner(true);
      if (this.useSkeleton) this.setIsLoadingRowData(true);

      if (sortedParam) {
        const { data } = await SortingApi.setSortingMode({
          resourceType: sortedParam.resourceType,
          sortingMode: sortedParam.sortingMode,
          ascending: sortedParam.ascending,
        });
        this.setUserData(data.response);
      }
      const { paramLibraryId: libraryId } = this;
      let pageId = null;
      if (this.isWorkspacePage || this.isSharedPage) {
        pageId = this.$route.params?.pageId;
        await this.listWorkspacePages({
          accessToken: this.accessToken,
          pageId: this.$route.params?.pageId,
          workspaceId: this.$route.params?.workspaceId,
        });
      }
      if (this.activeHeader?.kind == 'default' || this.isSharedPage) {
        await Promise.all(this.filteredListOfCollections.map(async el => {
          await this.getCollectionsList({
            libraryId,
            collectionGroup: el.group,
            allowGetAllItems: el?.allowGetAllItems,
            pageId,
            isWsPage: this.isWorkspacePage,
            showSpinner: !this.useLazyLoading,
            filteredListOfCollection: this.filteredListOfCollections,
            ...(this.accessToken && {
              accessToken: this.accessToken,
            }),
            resetCancellation: true,
            comingFromRoute: this.$route.name,
          });
        }));
      } else {
        if (sortedParam) {
          await this.getCustomLibraryCollectionsList({
            libraryId,
            workspaceId: this.activeWorkspaceId,
          });
        }
      }
      if (this.useSkeleton) this.setIsLoadingRowData(false);
      this.setSpinner(false);
    },
    async onIntersect({
      entries,
      isIntersecting,
      list: listName,
      collectionGroup,
      pagination,
      expandedAmount = 0,
      rowsToShow = 0,
    }) {
      await this.$nextTick();
      const nextToken = this.collectionOptions(pagination)?.nextToken;
      const { paramLibraryId: libraryId } = this;
      const list = this.collectionList({
        list: listName, group: collectionGroup, expandedAmount, rowsToShow,
      });
      if (isIntersecting) {
        const collectionId = entries[0].target.id;
        const lastCollectionId = list[list.length - 1].id;
        if (collectionId === lastCollectionId
          && nextToken
        ) {
          this.setSpinner(true);
          await this.getCollectionsList(
            {
              nextTokenForRequest: nextToken,
              collectionGroup,
              libraryId,
              pageId: this.$route.params?.pageId,
              isWsPage: this.isWorkspacePage,
              showSpinner: !this.useLazyLoading,
              ...(this.accessToken && {
                accessToken: this.accessToken,
              }),
            });
          this.setSpinner(false);
        }
      }
    },
    setSpinner(show) {
      if (!this.useSkeleton && !this.useLazyLoading) {
        this.spinner(show);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>