var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-dialog',{attrs:{"value":_vm.isOpenedDialog,"width":"440","content-class":"v-dialog__form"},on:{"update:value":function($event){_vm.isOpenedDialog=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"px-0"},'div',attrs,false),on),[_vm._t("buttonWrapper",function(){return [_c('p',[_c('app-icon-btn',{attrs:{"icon-height":"17px","wrapper-class":"px-0","show-tooltip":"","bottom":"","icon-name":"mdi-plus","icon-color":"lightGrey"}},[_vm._v(" Add to project ")])],1)]},{"btnText":'Add to project'})],2)]}}],null,true),model:{value:(_vm.isOpenedDialog),callback:function ($$v) {_vm.isOpenedDialog=$$v},expression:"isOpenedDialog"}},[_c('div',{staticClass:"dialog-content"},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v("Add to project")])]),_c('span',{staticClass:"sourcery__icon-wrapper black",on:{"click":function($event){_vm.isOpenedDialog = false}}},[_c('v-icon',{attrs:{"color":"white","size":"15"}},[_vm._v(" mdi-close ")])],1)]),_c('v-card-text',{staticClass:"items-wrapper"},[_c('div',{staticClass:"items"},[_c('div',{staticClass:"item-wrapper"},[_c('v-autocomplete',{staticClass:"duplicate-products-autocomplete",attrs:{"allow-overflow":false,"disabled":_vm.showSpinner,"items":_vm.activeProjectsBySearchText,"multiple":false,"menu-props":{
                maxHeight: '220px',
              },"search-input":_vm.projectSearchText,"color":"black","item-text":"projectTitle","item-value":"id","placeholder":"Choose project to add product","attach":"","return-object":"","clearable":"","chips":""},on:{"update:searchInput":function($event){_vm.projectSearchText=$event},"update:search-input":function($event){_vm.projectSearchText=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',{staticClass:"pl-4"},[_vm._v(" No projects available ")])]},proxy:true},{key:"item",fn:function(ref){
              var item = ref.item;
              var on = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","disabled":!item.disabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var onTooltip = ref.on;
return [_c('v-layout',_vm._g({staticStyle:{"padding":"0"},attrs:{"wrap":""}},onTooltip),[_c('v-list-item',_vm._g({attrs:{"ripple":false}},on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.projectTitle))])],1)],1)],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.reason)+" ")])])]}}]),model:{value:(_vm.projectToAddProduct),callback:function ($$v) {_vm.projectToAddProduct=$$v},expression:"projectToAddProduct"}})],1)])]),_c('v-card-actions',{staticClass:"pt-6 d-flex justify-end"},[[_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){_vm.isOpenedDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"white--text",attrs:{"ripple":false,"loading":_vm.showSpinner,"color":"blue","elevation":"0"},on:{"click":_vm.addToProject}},[_vm._v(" Add ")])]],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }