<template>
  <div
    v-if="isImagePdf && !isThumbnail"
    class="wrapper-pdf"
    :style="[!disabledCustomSizePdf && previewPdfStyles[fileId]]"
    @click.stop="setFilesPreview">
    <!-- For description with reduceProportions see image-preview__file class -->
    <AppProxyPdf
      :ref="'pdf-'.concat(fileId)"
      class="image-preview__img file-image__pdf"
      :disabled-custom-size-pdf="disabledCustomSizePdf"
      :reduce-proportions="92"
      :is-expanded-mode="isExpandedMode"
      :size-settings="getSizeSettings"
      :src="url"
      @loaded="onPdfLoad"
      @progress="onPdfProgress" />
  </div>
  <img
    v-else-if="url"
    :src="url"
    class="image-preview__img pointer"
    :alt="file.filename"
    @error="onError"
    @click.stop="setFilesPreview">
</template>
<script>
import AppProxyPdf from '@/components/App/AppProxyPdf/index.vue';
import { mapGetters } from 'vuex';
import { DEFAULT_THUMBNAILS_SIZES } from '@/constants/thumbnails';
export default {
  name: 'ImgPdfFile',
  components: {
    AppProxyPdf,
  },
  props: {
    getUrlFromThumbnail: {
      type: String,
      default: '',
    },
    disabledCustomSizePdf: {
      type: Boolean,
      default: false,
    },
    thumbnailsSize: {
      type: String,
      default: '',
    },
    previewPdfStyles: {
      type: Object,
      default: () => {
      },
    },
    file: {
      type: Object,
      required: true,
    },
    isExpandedMode: {
      type: Boolean,
      default: true,
    },
    transformImagesToData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      url: '',
      isThumbnail: false,
    };
  },
  computed: {
    ...mapGetters('FeatureFlags', [
      'isVisiblePrintButton',
    ]),
    fileId() {
      const { id } = this.file || {
      };
      return id;
    },
    getSizeSettings() {
      if (this.isExpandedMode) {
        return null;
      }
      if (this.isThumbnail) {
        return this.file?.thumbnails[this.thumbnailsSize];
      }
      return DEFAULT_THUMBNAILS_SIZES()[this.thumbnailsSize];
    },
    isImagePdf() {
      const { type = '' } = this.file || {
      };
      return type === 'application/pdf';
    },
  },
  watch: {
    file: {
      deep: true,
      immediate: true,
      handler(val) {
        this.setUrl(val);
      },
    },
    getUrlFromThumbnail() {
      this.setUrl(this.file);
    },
  },
  methods: {
    async setUrl(val) {
      const { getUrlFromThumbnail: thumbnail } = this;
      const { thumbnails, url = '' } = val ?? {
      };
      if (thumbnail) {
        const { [thumbnail]: thumbObj } = thumbnails ?? {
        };
        const { url: urlThumbnail = '' } = thumbObj || {
        };
        const awsUrl = urlThumbnail || url;
        if (awsUrl) {
          this.url = await this.toDataUrl(awsUrl);
        }
        this.isThumbnail = Boolean(urlThumbnail);
      } else {
        if (url) {
          this.url = await this.toDataUrl(url);
        }
      }
    },
    async toDataUrl(url = '') {
      if (!this.isVisiblePrintButton || !url || !this.transformImagesToData) {
        return url;
      } else {
        return new Promise((resolve, reject) => {
          let xhr = new XMLHttpRequest();
          xhr.onload = function () {
            const reader = new FileReader();
            reader.onloadend = function () {
              resolve(reader.result);
            };
            reader.readAsDataURL(xhr.response);
          };
          xhr.onerror = () => {
            reject({
              status: this.status,
              statusText: xhr.statusText,
            });
          };
          xhr.open('GET', url);
          xhr.responseType = 'blob';
          xhr.send();
        });
      }
    },
    onPdfLoad() {
      const { file, fileId } = this;
      const pdfProvider = this.$refs['pdf-'.concat(fileId)].pdf;
      this.$emit('onPdfLoad', file, pdfProvider);
    },
    onPdfProgress() {
      this.$emit('onPdfProgress');
    },
    setFilesPreview() {
      this.$emit('setFilesPreview');
    },
    onError() {
      this.$emit('onErrorCustom', this.file);
    },
  },
};
</script>
