<template>
  <div>
    <app-dialog
      v-model="isOpenDialog"
      :value.sync="isOpenDialog"
      width="450px"
      content-class="v-dialog__form attachments-documents-wrapper">
      <v-card>
        <v-card-title>
          <div class="d-flex align-center justify-space-between w-100">
            <div>
              <div>
                Associated Type(s)
              </div>
              <div class="caption">
                Select the row types the document is associated with
              </div>
            </div>
            <v-icon
              color="#fff"
              @click="$emit('controlEditMode', false)">
              mdi-close
            </v-icon>
          </div>
        </v-card-title>
        <v-card-text class="content">
          <template v-if="filteredAllTypes.length">
            <v-checkbox
              v-for="type in filteredAllTypes"
              :key="type.id"
              v-model="valueTypes"
              :disabled="type.status === 'deleted'"
              hide-details
              multiple
              :value="type.id"
              :label="type.name" />
          </template>
          <div
            v-else
            class="no-data">
            <p class="text--disabled">
              No data available
            </p>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            outlined
            @click="isOpenDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="blue"
            class="white--text"
            @click="save">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </app-dialog>
    <div class="wrapper">
      <span class="content-types">{{ renderingTypesString }}</span>
    </div>
  </div>
</template>
<script>
import { uniqBy } from 'lodash';
export default {
  name: 'DocumentCellAssociatedType',
  props: {
    documentId: {
      type: String,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    arrTypeDesignation: {
      type: Array,
      default: () => [],
    },
    selectedAssociatedTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      valueTypes: [],
    };
  },
  computed: {
    valueForSave() {
      return this.valueTypes.filter(e => !this.selectedTypeIds.includes(e));
    },
    valueForDelete() {
      return this.selectedTypeIds.filter(e => !this.valueTypes.includes(e));
    },
    renderingTypesString() {
      return this.selectedTypeNames.length ? this.selectedTypeNames.join(', ') : '';
    },
    filteredAllTypes() {
      const contactArr = [...this.arrTypeDesignation, ...this.selectedAssociatedTypes];
      return uniqBy(contactArr, 'id');
    },
    selectedTypeNames() {
      return this.selectedAssociatedTypes.map(e => e.name);
    },
    selectedTypeIds() {
      return this.selectedAssociatedTypes.map(e => e.id);
    },
    isOpenDialog: {
      get() {
        return this.editMode;
      },
      set(val) {
        this.$emit('controlEditMode', val);
      },
    },
  },
  watch: {
    isOpenDialog: {
      handler(val) {
        if (val) this.valueTypes = this.selectedTypeIds;
      },
      immediate: true,
    },
  },
  methods: {
    save() {
      const { valueForSave, valueForDelete } = this;
      if (!valueForSave.length && !valueForDelete.length) this.isOpenDialog = false;
      else {
        this.$emit('addAssociatedType', valueForSave, valueForDelete, this.documentId);
        this.isOpenDialog = false;
      }
    },
  },
};
</script>
<style scoped>
.content-types {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  scroll-behavior: smooth;
  word-break: break-word;
  width: 100%;
}
.content {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  min-height: 150px;
  overflow: auto;
}
.no-data {
  align-self: center;
  margin-top: 30px;
}
.v-input--selection-controls {
  margin-top: 5px !important;
}
</style>
