<template>
  <div
    :class="{'border-solo': isExpandedMode }"
    class="wrapper d-flex align-center flex-column">
    <div
      v-if="filterDocument.length"
      ref="link-wrapper"
      class="link-wrapper link-wrapper__associated-docs">
      <ol ref="orderedList">
        <li
          v-for="item in filterDocument"
          :key="item.id"
          class="subtitle-2 link"
          @click.stop="setFilePreview(item)">
          {{ item.tag && item.tag + ',' }}
          {{ item.filename + ', ' }}
          {{ item.description && item.description + ',' }}
          {{ $moment(item.uploadedAt).format('YYYY-MM-DD') }},
          {{ item.uploadedBy.name }}
        </li>
      </ol>
    </div>
    <p
      v-if="isShowMoreDocsBlock"
      class="small-p link link--more-docs mt-1 mb-0">
      Click here to see more documents
    </p>
    <v-tooltip
      v-if="!isExpandedMode"
      bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          color="blue"
          class="pointer icon-action"
          v-on="on"
          @click="!hasAccessEditor ? $emit('messagesNoHasAccessEditor') : $emit('setEditMode' , true)">
          mdi-folder
        </v-icon>
      </template>
      <span>Go to project documents</span>
    </v-tooltip>

    <ProjectDetailsDocuments
      :ref="currentTypeDocument"
      :document-type="currentTypeDocument"
      :id-file-upload="currentTypeDocument"
      hide-btn
      @clearAllItems="updateAssociatedRows" />
  </div>
</template>
<script>
import ProjectDetailsDocuments from '@/components/ProjectDetails/ProjectDetailsDocuments';
import { TYPE_DOCUMENTS } from '@/constants/projectDocument';
import {
  mapActions, mapMutations, mapState,
} from 'vuex';
export default {
  name: 'ProductAssociatedDocumentV2',
  components: {
    ProjectDetailsDocuments,
  },
  props: {
    isExpandedMode: {
      type: Boolean,
      default: true,
    },
    hasAccessEditor: {
      type: Boolean,
      default: true,
    },
    isOverlayed: {
      type: Boolean,
      default: false,
    },
    col: {
      type: String,
      default: '',
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    currentRowId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filesForPreview: [],
      isOverflowed: false,
    };
  },
  computed: {
    ...mapState({
      associatedDocuments: state => state.ProjectDetails.associatedDocuments,
    }),
    isShowMoreDocsBlock() {
      const { isOverlayed, isOverflowed } = this;
      return !isOverlayed && isOverflowed;
    },
    filterDocument() {
      return this.items.filter(e => e.associatedTypes.length && e.associatedTypes.some(e => e.id === this.currentRowId));
    },
    items() {
      return this.associatedDocuments[this.currentTypeDocument] || [];
    },
    currentTypeDocument() {
      return TYPE_DOCUMENTS.find(e => e.column === this.col)?.type;
    },
  },
  watch: {
    //TODO can be used in description product cell
    isOverlayed(val) {
      if (!val) {
        if (!this.$refs['link-wrapper']) return false;
        this.$refs['link-wrapper'].scrollTop = 0;
        this.checkOverlaying();
      }
    },
    editMode(val) {
      val && this.$refs[this.currentTypeDocument].openDialog();
    },
    filterDocument: {
      async handler() {
        await this.$nextTick();
        this.checkOverlaying();
      },
    },
  },
  mounted() {
    this.checkOverlaying();
  },
  methods: {
    ...mapActions({
      handleError: 'handleError',
    }),
    ...mapMutations({
      spinner: 'spinner',
      setFilesForPreview: 'setFilesForPreview',
    }),
    checkOverlaying() {
      const { clientHeight, scrollHeight } = this.$refs.orderedList || {
      };
      this.isOverflowed = scrollHeight > clientHeight;
    },
    setFilePreview(item) {
      this.setFilesForPreview([item]);
    },
    updateAssociatedRows() {
      this.$emit('getDocuments');
      this.$emit('setEditMode', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.border-solo {
  min-height: 40px;
  padding: 5px;
  align-items: flex-start !important;
  background-color: var(--v-lightGrey-base);
}
.icon-action {
  position: absolute;
  right: 0;
  bottom: 0;
}
.link {
  &:not(:first-child) {
    margin-top: 5px;
  }
  line-height: 130%;
  transition: .3s;
  color: var(--v-blue-base);
  width: fit-content;
  &--more-docs {
    opacity: 0.6;
    text-align: center;
  }
  &:hover {
    color: var(--v-blue-base);
  }
}
.link-wrapper {
  scroll-behavior: unset !important;
  word-break: break-word;
}
.wrapper {
  height: initial;
  width: 100%;
}
</style>
