var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[(!_vm.activeHeader)?_c('div',{staticClass:"sourcery__container"},[_c('CollectionsBaseSkeleton',{attrs:{"show-search":true}})],1):(_vm.isEmpty && _vm.activeHeader)?_c('AppEmptyList',{staticClass:"pt-32",attrs:{"from":_vm.isLibraryCollections ? 'library-collections' : 'collections'}}):[_c('CollectionsSearch',_vm._b({attrs:{"is-store-product-preload":_vm.isStoreProductPreload,"custom-search-keyword":_vm.isWorkspacePage ? (_vm.workspacePage.name || '') : ''}},'CollectionsSearch',_vm.propsDataForSearching,false)),(!_vm.searchProductsMode)?_c('div',{staticClass:"sourcery__container d-flex flex-column gap-6"},[_c('div',{staticClass:"d-flex justify-space-between gap-4"},[_c('div',{staticClass:"d-flex gap-4 align-center"},[_c('AppSelect',{attrs:{"default-item":_vm.selectedCollectionGroup,"items":_vm.filteredListofCollectionGroups()},on:{"resort":function($event){_vm.selectedCollectionGroup = $event}}}),_c('router-link',{staticClass:"fs-14 black--text text-uppercase",attrs:{"to":{ name: 'libraries' }}},[_vm._v(" View Libraries ")])],1),_c('div',{staticClass:"d-flex gap-4"},[_c('AppSort',{attrs:{"default-item":_vm.defaultItem,"sort-items":_vm.sortItems},on:{"resort":_vm.fetchCollections}}),_c('AppSwitcherToListing')],1)]),(_vm.isListingView)?_c('CollectionListing',{attrs:{"collections-list":_vm.filteredList,"group":_vm.selectedCollectionGroup.group},on:{"click-action-menu":function($event){return _vm.onClickActionMenu(Object.assign({}, $event))},"onIntersect":function($event){return _vm.onIntersect(Object.assign({}, $event,
          {list: _vm.collection.list,
          collectionGroup: _vm.collection.group,
          pagination: _vm.collection.paginationState,
          allowGetAllItems: _vm.collection.allowGetAllItems}))}}}):_c('CollectionsTable',{attrs:{"collections-list":_vm.filteredList,"group":_vm.selectedCollectionGroup.group},on:{"click-action-menu":function($event){return _vm.onClickActionMenu(Object.assign({}, $event))},"onIntersect":function($event){return _vm.onIntersect(Object.assign({}, $event,
          {list: _vm.collection.list,
          collectionGroup: _vm.collection.group,
          pagination: _vm.collection.paginationState,
          allowGetAllItems: _vm.collection.allowGetAllItems}))}}})],1):_vm._e()],_c('div',{staticClass:"buttons-container"},[(!_vm.searchProductsMode)?_c('CollectionCreateOrUpdate',_vm._g({},_vm.$listeners)):_vm._e(),_c('CollectionsCreateProductDialog',_vm._g({attrs:{"prop-library-id":_vm.currentLibraryId,"is-store-product-preload":_vm.isStoreProductPreload}},_vm.$listeners))],1),_c('ShareAdvancedDialog',{attrs:{"start-subscribe":true,"disable-actions":_vm.disableActionsSharing,"disabled":!(_vm.disableActionsSharing && !_vm.activeCollection.follow),"invite-to-text-type":_vm.COLLECTION,"item":_vm.activeCollection},on:{"manageSharedUser":function($event){return _vm.manageSharedUser({
        variables: Object.assign({}, $event.variables,
          {collectionId: _vm.activeCollection.id}),
        criteria: $event.criteria,
      })}}}),_c('CollectionsActions',_vm._b({attrs:{"show-activator":false,"item":_vm.selectedItem,"position-x":_vm.positionX,"position-y":_vm.positionY,"is-community-collections":_vm.isCommunityCollections,"value":_vm.showActionsCard},on:{"update:item":function($event){_vm.selectedItem=$event},"update:value":function($event){_vm.showActionsCard=$event},"manageActions":_vm.manageActions}},'CollectionsActions',_vm.actionsProps,false))],2)}
var staticRenderFns = []

export { render, staticRenderFns }